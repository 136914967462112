/* eslint-disable */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 // Load tailwindcss
require('typeface-inter');
require('tailwindcss/dist/base.min.css');

module.exports.onInitialClientRender = require('./src/gatsby/browser/onInitialClientRender');
module.exports.onRouteUpdate = require('./src/gatsby/browser/onRouteUpdate');
// module.exports.shouldUpdateScroll = require('./src/gatsby/browser/shouldUpdateScroll');
module.exports.onClientEntry = require('./src/gatsby/browser/onClientEntry');