import prism from "./prism";

/** 
 * Define your website's color scheme in this file
 * Add multiple modes by naming it and adding it in the 'modes' object
 */
export default {
  prism,

  text: "#000",
  primary: "#000",
  secondary: "rgba(0, 0, 0, 0.7)",
  grey: "#676771",
  background: "#fff",
  backgroundPair: "#fafafa",
  backgroundPairOpaque: "rgba(250, 250, 250, 0.7)",
  backgroundDark: "#f1f1f1",
  accent: "#62BF86",
  accentPair: "#fff",
  accentHover: "#4c7332",
  accentActive: "#375424",
  accentOpaque: "rgba(55, 84, 36, 0.1)",
  secondaryAccent: "#0D6A8C",
  tertiaryAccent: "#10A89B",
  boxShadow: "rgba(0, 0, 0, 0.1)",
  boxShadowHover: "rgba(94, 142, 62, 0.2)",
  boxShadowActive: "rgba(94, 142, 62, 0.3)",
  hover: "rgba(0, 0, 0, 0.07)",
  gradient: "linear-gradient(180deg, rgba(217, 219, 224, 0) 0%, #D9DBE0 100%)",
  articleText: "#08080B",
  track: "rgba(8, 8, 11, 0.3)",
  progress: "#000",
  card: "#fff",
  error: "#EE565B",
  success: "#46B17B",
  errorBackground: "rgba(238, 86, 91, 0.1)",
  horizontalRule: "rgba(8, 8, 11, 0.15)",
  inputBackground: "rgba(0, 0, 0, 0.05)",
  inputBackgroundPair: "rgba(255, 255, 255, 0.95)",

  modes: {
    dark: {
      text: "#fff",
      primary: "#fff",
      secondary: "rgba(17, 18, 22, 0.7)",
      grey: "#878794",
      background: "#111216",
      backgroundPair: "#0D0D10",
      backgroundPairOpaque: "rgba(13, 13, 16, 0.7)",
      backgroundDark: "#08080A",
      accent: "#E9DAAC",
      accentPair: "#111216",
      accentHover: "#D4C79D",
      accentActive: "#BFB38D",
      accentOpaque: "rgba(233, 218, 172, 0.1)",
      boxShadow: 'rgba(8,8,10,0.75)',
      boxShadowHover: "rgba(233, 218, 172, 0.2)",
      boxShadowActive: "rgba(233, 218, 172, 0.3)",
      hover: "rgba(255, 255, 255, 0.07)",
      gradient:
        "linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)",
      articleText: "#fff",
      track: "rgba(255, 255, 255, 0.3)",
      progress: "#fff",
      card: "#1D2128",
      error: "#EE565B",
      success: "#46B17B",
      errorBackground: "rgba(238, 86, 91, 0.1)",
      horizontalRule: "rgba(255, 255, 255, 0.15)",
      inputBackground: "rgba(255, 255, 255, 0.07)",
      inputBackgroundPair: "rgba(17, 18, 22, 0.95)",
    },
  },
};
