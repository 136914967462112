import merge from 'lodash/merge';

import colors from './colors';
import tags from './tags';

/** 
 * Define your website's specifications and attributes in this file
 * This template uses theme-ui in order to leverage the power of theming
 * 
 * Create your own theme: https://theme-ui.com/customize
 * 
 * Recommended specs to define:
 *  - breakpoints
 *  - fontSizes
 *  - fontWeights
 *  - lineHeights
 *  - fonts
 * 
 * Optional specs:
 *  - transitions
 *  - shadows
 *  - radii
 *  - zIndices
 * 
 * https://theme-ui.com/theme-spec/
 */

const breakpoints = [
  ['mobile_i6', 375], // Android, Google Phone
  ['mobile_i6p', 414], // Iphone 6+
  ['tablet', 660], // Other tablets
  ['tablet_ipad', 768], // Ipad tablets
  ['laptop', 1000], // Smaller screen
  ['desktop', 1200], // Normal desktop size
  ['desktop_big', 1440], // Big desktop size
];

const fontSizes = {
  small: "14px",
  base: "16px",
  big:  "18px",
};

const fontWeights = {
  body: 400,
  heading: 700,
  
  semibold:  600,
  bold: 700,
  extrabold: 900,
};

const lineHeights = {
  body: 1.6,
  heading: 1.3
};

const fonts = {
  serif: `'Inter', 'Merriweather', Georgia, Serif`,
  sansSerif: `'Inter', 'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif`,

  body: `'Inter', 'Merriweather', Georgia, Serif`,
  heading: `'Inter', 'Merriweather', Georgia, Serif`,
  monospace: `"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
};

const colorModeTransition =
  `background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)`;

const transitionValue =
  `0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);`;

const cardBoxShadow =
  `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);`;

const cardBoxShadowDark =
  `0 10px 15px -3px ${colors.boxShadow}, 0 10px 10px -2px ${colors.boxShadow};`;

const boxShadow = (color: string = colors.boxShadow) => (
  `0px 8px 10px -3px ${color};`
);
const boxShadowHover = (color: string = colors.boxShadowHover) => (
  `0px 10px 15px -2px ${color};`
);
const boxShadowActive = (color: string = colors.boxShadowActive) => (
  `0px 10px 15px -2px ${color};`
);

export default merge({
  initialColorMode: 'light',
  useCustomProperties: true,
  colorModeTransition,
  transitionValue,
  cardBoxShadow,
  cardBoxShadowDark,
  boxShadow,
  boxShadowHover,
  boxShadowActive,
  colors,
  fonts,
  breakpoints,
  tags,
  fontSizes,
  fontWeights,
  lineHeights,
});
